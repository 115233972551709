<template>
  <div style="width: 100% ;padding: 10px">
    <div style="margin: 10px 0">
      <el-select v-model="styletap" placeholder="请选择">
        <el-option
            v-for="item in stylelist"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>
    </div>
    <el-row :gutter="10" style="margin-bottom: 60px">
      <el-col :span="6">
        <el-card style="color: #409EFF">
          <div><i class="el-icon-user-solid" /> 合作商总数</div>
          <div style="padding: 10px 0; text-align: center; font-weight: bold">
            {{this.getChangShangList.length}}
          </div>
        </el-card>
      </el-col>
<!--      <el-col :span="6">
        <el-card style="color: #F56C6C">
          <div><i class="el-icon-money" /> 销售总量</div>
          <div style="padding: 10px 0; text-align: center; font-weight: bold">
            ￥ 1000000
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card style="color: #67C23A">
          <div><i class="el-icon-bank-card" /> 收益总额</div>
          <div style="padding: 10px 0; text-align: center; font-weight: bold">
            ￥ 300000
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card style="color: #E6A23C">
          <div><i class="el-icon-s-shop" /> 门店总数</div>
          <div style="padding: 10px 0; text-align: center; font-weight: bold">
            20
          </div>
        </el-card>
      </el-col>-->
    </el-row>

    <el-row v-show="styletap == '可视化界面'">
      <el-select v-model="tableStyle" placeholder="请选择">
        <el-option
            v-for="item in tableStyleList"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>
      <el-select v-model="value2" @change="echartsDataSelect" placeholder="请选择时间">
        <el-option
            v-for="item in options2"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-button @click="bodyChange = !bodyChange;showChange()">坐标翻转</el-button>
      <el-button @click="showChange">查询</el-button>
      <el-switch v-model="rukuSelectValue" @change="switchOpen" active-text="仅筛选已入库工件" style="padding-left: 10px"/>
      <el-col>
        <el-carousel height="500px" :autoplay="false" indicator-position="outside" v-loading.fullscreen.lock="loading">
          <el-carousel-item>
            <el-card style="padding-left: 5% ; width: 95%">
              <div v-show="!isPie" id="main" style="width: 1200px; height: 450px"></div>
              <div v-show="isPie" id="pie" style="width: 1200px; height: 1000px"></div>
            </el-card>
          </el-carousel-item>
          <el-carousel-item>
            <el-card>
              <div id="person" style="width: 1200px; height: 450px"></div>
            </el-card>
          </el-carousel-item>
          <el-carousel-item>
            <el-card>
              <div id="progress" style="width: 1200px; height: 450px"></div>
            </el-card>
          </el-carousel-item>
        </el-carousel>
      </el-col>

    </el-row>
    <el-row v-show="styletap == '报表界面'">
      <el-select v-model="value" placeholder="请选择时间">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="showBy" placeholder="请选择">
        <el-option
            v-for="item in showByList"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>
      <el-select v-model="selectFactery" v-if="showBy == '指定厂商查询'" placeholder="请选择厂商">
        <el-option
            v-for="item in getChangShangList"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>
      <el-button @click="getList">查询</el-button>
      <div>
        <el-col :span="14">
          <el-table
              :data="showList"
              :span-method="objectSpanMethod"
              v-if="showHistery == '按供应商分类'"
              border
              style="width: 100%">
            <el-table-column
                label="厂商"
                prop="factory"
                width="100">
            </el-table-column>
            <el-table-column
                label="工件型号"
                prop="serialName"
                width="200">
            </el-table-column>
            <el-table-column
                label="图纸号"
                prop="paperNumber"
                width="200">
            </el-table-column>
            <el-table-column
                label="加工数量"
                prop="number"
                width="100">
            </el-table-column>
            <el-table-column
                label="成品数量"
                prop="chengpinNum"
                width="100">
            </el-table-column>
          </el-table>
          <el-table
              :data="showList"
              :span-method="objectSpanMethod"
              v-if="showHistery == '按产品分类'"
              border
              style="width: 100%">
            <el-table-column
                label="工件型号"
                prop="serialName"
                width="200">
            </el-table-column>
            <el-table-column
                label="图纸号"
                prop="paperNumber"
                width="200">
            </el-table-column>
            <el-table-column
                label="厂商"
                prop="factory"
                width="100">
            </el-table-column>
            <el-table-column
                label="加工数量"
                prop="number"
                width="100">
            </el-table-column>
            <el-table-column
                label="成品数量"
                prop="chengpinNum"
                width="100">
            </el-table-column>
          </el-table>
          <el-table
              :data="showList"
              v-if="showHistery == '指定厂商查询'"
              border
              style="width: 100%">
            <el-table-column
                label="工件型号"
                prop="serialName"
                width="200">
            </el-table-column>
            <el-table-column
                label="图纸号"
                prop="paperNumber"
                width="200">
            </el-table-column>
            <el-table-column
                label="加工数量"
                prop="number"
                width="100">
            </el-table-column>
            <el-table-column
                label="成品数量"
                prop="chengpinNum"
                width="100">
            </el-table-column>
          </el-table>
          <div style="margin: 10px 0">
            <el-pagination
                :current-page="currentPage4"
                :page-size="size"
                :page-sizes="[10, 20, 50]"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
            </el-pagination>
          </div>
        </el-col>
      </div>
    </el-row>
    <div>
      <el-dialog :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
        <div id="progress" style="width: 85%; height: 450px"></div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import request from "@/utils/request";

export default {
  name: "Home",
  data() {
    return {
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      isPie:false,
      currentPage4: 1,
      facteryList:[],
      size: 20,
      total: 0,
      getChangShangList:[],
      selectFactery:'',
      tableStyleList:['线型图','柱型图','饼状图'],
      tableStyle:'柱型图',
      options: [
        {
          value: '本年',
          label: '本年'
        }, {
          value: '去年',
          label: '去年'
        }, {
          value: '本季度',
          label: '本季度'
        }, {
          value: '上季度',
          label: '上季度'
        }, {
          value: '本月',
          label: '本月'
        }, {
          value: '上个月',
          label: '上个月'
        }, {
          value: '6个月内',
          label: '6个月内'
        }, {
          value: '本周',
          label: '本周'
        }, {
          value: '上周',
          label: '上周'
        }],
      options2: [
        {
          value: '本年',
          label: '本年'
        }, {
          value: '去年',
          label: '去年'
        }, {
          value: '本季度',
          label: '本季度'
        }, {
          value: '上季度',
          label: '上季度'
        }, {
          value: '本月',
          label: '本月'
        }, {
          value: '上个月',
          label: '上个月'
        }, {
          value: '6个月内',
          label: '6个月内'
        }, {
          value: '本周',
          label: '本周'
        }, {
          value: '上周',
          label: '上周'
        }],
      value: '本年',
      value2: '本年',
      //stylelist:['报表界面'],
      stylelist:['报表界面','可视化界面'],
      styletap:'可视化界面',
      showByList:['按供应商分类','按产品分类','指定厂商查询'],
      showBy:'按供应商分类',
      showHistery:'按供应商分类',//上次查询分类
      getedList:[],
      showList:[],
      //单元格合并：
      firArr: [],  // 第一列进行合并操作时存放的数组变量
      firPos: 0, // 上面的数组的下标值
      secArr: [],  // 第二列进行合并操作时存放的数组变量
      secPos: 0,// 上面的数组的下标值
      /*theArr: [],  // 第二列进行合并操作时存放的数组变量
      thePos: 0,// 上面的数组的下标值*/

      bodyChange: false,//echarts横纵坐标反转
      echartsData: [],
      echartsPersonData: [],
      echartsProgressData: [],
      personList: [],
      dialogVisible: false,//弹窗
      rukuSelectValue: false,//仅筛选入库工件
      containDeLin: true,//包含德林工件
      loading: true,
    }
  },
  mounted() {  // 页面元素渲染之后再触发
    request.get("/echarts/getChangShangList").then(res1 =>{
      this.getChangShangList = res1.data
      this.selectFactery = res1.data[0]
    })
    this.getList();
    request.post("/GetFactury").then(res => {
      this.facteryList = res.data;
      this.echartsDataSelect()
    })
  },
  methods: {
    // async dataSelect(){
    //
    // },
    async dataSelect(){
      if (this.rukuSelectValue){
        await this.request.post("/echarts/membersRuku",{s: this.value2,facteryList: this.facteryList}).then(res => {
          this.echartsData = res.data
        })
        await this.request.post("/echarts/membersProcessRuku",{s: this.value2}).then(res => {
          this.echartsPersonData = res.data
        })

        let PersonalStatisticsReq = {
          time: this.value2,
          id: 33
        }
        await request.post("/PersonalListStatisticsFinished",PersonalStatisticsReq).then(res =>{
          let personList = []
          for (let i = 0; i < res.data.length; i++) {
            if (!res.data[i].personList){
              continue
            }
            for (let j = 0; j < res.data[i].personList.length; j++) {
              if (res.data[i].personList[j] &&
                  res.data[i].personList[j].name){
                let _personIndex = personList.findIndex((_item) => (res.data[i].personList[j].name === _item.name));
                if (_personIndex === -1){
                  let _person = {name:res.data[i].personList[j].name , proList:new Array(res.data.length).fill(0)}
                  _person.proList[i] += res.data[i].personList[j].worknum
                  personList.push(_person)
                } else {
                  let _person = personList[_personIndex]
                  _person.proList[i] += res.data[i].personList[j].worknum
                  personList[_personIndex] = _person
                }
              }
            }
          }
          this.personList = personList
          this.echartsProgressData = res.data
          //this.personList.unshift(item)
        })
      } else {
        await this.request.post("/echarts/members",{s: this.value2,facteryList: this.facteryList}).then(res => {
          this.echartsData = res.data
        })
        await this.request.post("/echarts/membersProcess",{s: this.value2}).then(res => {
          this.echartsPersonData = res.data
        })

        let PersonalStatisticsReq = {
          time: this.value2,
          id: 33
        }
        await request.post("/PersonalListStatistics",PersonalStatisticsReq).then(res =>{
          let personList = []
          for (let i = 0; i < res.data.length; i++) {
            if (!res.data[i].personList){
              continue
            }
            for (let j = 0; j < res.data[i].personList.length; j++) {
              if (res.data[i].personList[j] &&
                  res.data[i].personList[j].name){
                let _personIndex = personList.findIndex((_item) => (res.data[i].personList[j].name === _item.name));
                if (_personIndex === -1){
                  let _person = {name:res.data[i].personList[j].name , proList:new Array(res.data.length).fill(0)}
                  _person.proList[i] += res.data[i].personList[j].worknum
                  personList.push(_person)
                } else {
                  let _person = personList[_personIndex]
                  _person.proList[i] += res.data[i].personList[j].worknum
                  personList[_personIndex] = _person
                }
              }
            }
          }
          this.personList = personList
          // console.log(personList)
          this.echartsProgressData = res.data
          //this.personList.unshift(item)
        })
      }
      this.loading = false
    },
    async echartsDataSelect(){
      this.loading = true
      await this.dataSelect().then(() =>{
        this.showChange()
      })
      this.$nextTick(() => {
        this.loading = false
      })
    },
    showChange(){
      // document.getElementById('main').innerHTML = null
      // document.getElementById('pie').innerHTML = null
      // this.isPie = !this.isPie
      // this.isPie = !this.isPie
      let titleList = ["第一季度","第二季度","第三季度","第四季度"]
      let data = this.echartsData
      let nameList = this.facteryList
      if (this.bodyChange){
        let title2 = nameList;
        let name2 = titleList;
        let data2 = [];
        try{
          for (let i = 0; i < data.length; i++) {
            let child = data[i]
            try{
              for (let j = 0; j < child.length; j++) {
                if (!data2[j]){
                  data2[j] = []
                }
                data2[j].push(child[j])
              }
            }catch (e) {
            }
          }
        }catch (e) {
          console.log(e)
        }
        titleList = title2
        data = data2
        nameList = name2
      }
      this.$nextTick(()=>{
        if (this.tableStyle === '柱型图' || this.tableStyle === '线型图' ){
          this.setbar("各季度加工数量统计",titleList,data,nameList,"main")
          this.isPie = false;
        } else if (this.tableStyle === '饼状图'){
          this.setpie("各季度加工数量统计",titleList,data,nameList,"pie")
          this.isPie = true;
        }
      })
      this.setbarGroup("员工加工数量统计",this.echartsPersonData,"person","person","num")
      this.setProgressBar()
    },
    getGroupData(data,type){
      // console.log("data",data)
      if (this.containDeLin){
        let num = data.map((item) => {
          let i = 0;
          item[type].forEach((child)=>{
            i+=child;
          })
          return [i]
        })
        // console.log("num",num)
        return num
      } else {

        let num = []
        for (let i = 0; i < data.length; i++) {
          let item = data[i]
          let _num = 0
          for (let j = 0; j < item.pro.length; j++) {
            if (!item.pro[j].includes("德林")){
              _num += item.num[j];
            }
          }
          num.push([_num])
        }

        return num
        // this.$nextTick(() => {
        //   console.log("num",num)
        // })
      }
    },
    getGrouptitel(data,type){
      let lst = data.map((item) => {
        return item[type];
      })
      return lst
    },
    setProgressBar(){
      let option = {
        title: {
          text: "员工——工序表",
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        grid: {
          left:'18%',
          right:'8%'
        },
        xAxis: {
          type: 'category',
          data: this.echartsProgressData.map((item) => {
            return item.process
          }),
          axisLabel:{
            interval: 0,
            rotate: -45
          }
        },
        yAxis: {
          type: 'value'
        },
        series: this.getProgressSeries(),
      };

      let chartDom = document.getElementById("progress");
      let myChart = echarts.init(chartDom);

      // for (let i = 0; i < option.series.length; i++) {
      //   option.series[i].data = data[i]
      // }
      // 数据准备完毕之后再set
      myChart.setOption(option,true);
    },

    setbar(title,titleList,data,nameList,element){
      let option = {
        title: {
          text: title,
          subtext: '趋势图',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        xAxis: {
          type: 'category',
          data: titleList
        },
        yAxis: {
          type: 'value'
        },
        series: this.getSeries(nameList),
      };

      let chartDom = document.getElementById(element);
      let myChart = echarts.init(chartDom);

      for (let i = 0; i < option.series.length; i++) {
        option.series[i].data = data[i]
      }
      // 数据准备完毕之后再set
      myChart.setOption(option,true);
    },
    setbarGroup(title,data,element,elementType,elementDate){
      let option = {
        title: {
          text: title,
          subtext: '趋势图',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        grid: {
          left:'18%',
          right:'8%'
        },
        toolbox: {
          show: true,
          feature: {
            // dataZoom: {
            //   yAxisIndex: 'none'
            // },
            // dataView: { readOnly: true },
            // saveAsImage: {},
            myTool: {
              show: true, //是否显示
              title: this.containDeLin?"清除德林数据":"包含德林数据",
              icon: "path://M200.753 408.251c-57.062 0-103.749 46.687-103.749 103.749s46.687 103.749 103.749 103.749S304.502 569.062 304.502 512s-46.687-103.749-103.749-103.749z m622.494 0c-57.062 0-103.749 46.687-103.749 103.749s46.687 103.749 103.749 103.749S926.996 569.062 926.996 512s-46.687-103.749-103.749-103.749z m-311.247 0c-57.062 0-103.749 46.687-103.749 103.749S454.938 615.749 512 615.749 615.749 569.062 615.749 512 569.062 408.251 512 408.251z", //这个是阿里icon svg 后 b的全部字符串
              onclick: (o) => {
                //参数o 是图表的option 我这里并没有用到
                this.containDeLin = !this.containDeLin
                this.showChange()
              },
            },
          }
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        xAxis: {
          type: 'category',
          data: ["员工"]
        },
        yAxis: {
          type: 'value'
        },
        series: this.getSeriesGroup(data,"person"),
      };
      let chartDom = document.getElementById(element);
      let myChart = echarts.init(chartDom);

      let _groupData = this.getGroupData(data,elementDate);
      // console.log()
      for (let i = 0; i < option.series.length; i++) {
        option.series[i].data = _groupData[i]
      }
      // 数据准备完毕之后再set
      myChart.setOption(option,true);
      myChart.on('click', params => {
        this.dialogShow(params);
        // this.$emit('barClick',params)
      })
    },
    dialogShow(params){
      this.dialogVisible = true;
      this.$nextTick(()=>{
        this.setbarNormal(params.seriesName,this.echartsPersonData[params.componentIndex].num,this.echartsPersonData[params.componentIndex].pro,"progress")
      })
    },
    setbarNormal(title,data,titelList,element){
      let option = {
        title: {
          text: title,
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        xAxis: {
          type: 'category',
          data: ["员工"]
        },
        yAxis: {
          type: 'value'
        },
        series: this.getSeries(titelList),
      };

      let chartDom = document.getElementById(element);
      let myChart = echarts.init(chartDom);

      for (let i = 0; i < option.series.length; i++) {
        option.series[i].data = [data[i]]
      }
      // 数据准备完毕之后再set
      myChart.setOption(option,true);
    },
    setpie(title,titleList,data,nameList,element){

      // 饼图
      let pieOption = {
        title: {
          text: title,
          subtext: '比例图',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [],

      };
      let pieDom = document.getElementById(element);
      let pieChart = echarts.init(pieDom);

      let series = new Array(data.length)

      for (let i = 0; i < titleList.length; i++) {
        let _data = []
        for (let j = 0; j < data.length; j++) {
          if (data[j][i] !== 0){
            _data.push({value:data[j][i],name:nameList[j]})
          }
        }
        if (_data.length === 0){
          continue
        }
        let x = ((i%3) * 2 + 1) * 14 + 2
        let y = parseInt(parseInt((i/3)+1)/(parseInt(nameList.length/3)+2)*100)

        series.splice(i,1,{
          name: titleList[i],
          type: 'pie',
          radius: '20%',
          center: [x+'%', y+'%'],
          label:{            //饼图图形上的文本标签
            normal:{
              show:true,
              position:'inner', //标签的位置
              textStyle : {
                fontWeight : 300 ,
                fontSize : 14,    //文字的字体大小
                color: "#000000"
              },
              formatter:'{b}:{c}({d}%)'
            }
          },
          data: _data,  // 填空
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        })
      }
      pieOption.series = series;
      pieChart.setOption(pieOption,true)
    },
    getSeries(titleList){
      let series = []
      for (let i = 0; i < titleList.length; i++) {
        let type = 'bar'
        if (this.tableStyle === '柱型图'){
          type = 'bar'
        } else if (this.tableStyle === '线型图'){
          type = 'line'
        }
        series.push(
            {
              name: titleList[i],
              data: [],
              type: type
            }
        )
      }
      return series;
    },
    getProgressSeries(){
      let series = []
      for (let i = 0; i < this.personList.length; i++) {
        let type = 'bar'
        // if (this.tableStyle === '柱型图'){
        //   type = 'bar'
        // } else if (this.tableStyle === '线型图'){
        //   type = 'line'
        // }
        series.push(
            {
              name: this.personList[i].name,
              data: this.personList[i].proList,
              stack: 'total',
              type: type,
              label: {
                show: true,
                formatter:function (params) {
                  if(params.value===0){  //为0时不显示
                    return ''
                  }else{
                    return params.value
                  }
                }
              },
            }
        )
      }
      return series;
    },
    getSeriesGroup(data,elementType){

      function sum(arr){
        let i = 0;
        arr.forEach(item => {
          i += item
        })
        return i;
      }

      let series = []
      for (let i = 0; i < data.length; i++) {
        let type = 'bar'
        // if (this.tableStyle === '柱型图'){
        //   type = 'bar'
        // } else if (this.tableStyle === '线型图'){
        //   type = 'line'
        // }
        series.push(
            {
              name: data[i][elementType],
              data: data[i],
              type: type,
              label: {
                show: true,
                formatter:data[i][elementType]
              },
            }
        )
      }
      return series;
    },
    getList(){
      this.showHistery = this.showBy;
      this.currentPage4 = 1;
      if (this.showHistery === '按供应商分类'){
        request.post("/echarts/getListByChangShang",this.value).then(res1 =>{
          this.getedList = res1.data
          this.total = res1.data.length
          this.showList = this.getedList.slice((this.currentPage4 - 1) * this.size, this.currentPage4 * this.size);
          this.meragefactory();
        })
      } else if (this.showHistery === '按产品分类'){
        request.post("/echarts/getListBySerialName",this.value).then(res1 =>{
          this.getedList = res1.data
          this.total = res1.data.length
          this.showList = this.getedList.slice((this.currentPage4 - 1) * this.size, this.currentPage4 * this.size);
          this.merageserialName();
        })
      } else if (this.showHistery === '指定厂商查询'){
        request.post("/echarts/getListByOnlyFactery",{s: this.value,index: this.selectFactery}).then(res1 =>{
          this.getedList = res1.data
          this.total = res1.data.length
          this.showList = this.getedList.slice((this.currentPage4 - 1) * this.size, this.currentPage4 * this.size);
          this.merageserialName();
        })
      }
    },
    handleSizeChange(val) {
      this.size = val;
      this.showList = this.getedList.slice((this.currentPage4 - 1) * this.size, this.currentPage4 * this.size);
      if (this.showHistery == '按供应商分类'){
        this.meragefactory();
      } else if (this.showHistery == '按产品分类'){
        this.merageserialName();
      }
    },
    handleCurrentChange(val) {
      this.currentPage4 = val
      this.showList = this.getedList.slice((this.currentPage4 - 1) * this.size, this.currentPage4 * this.size);
      if (this.showHistery == '按供应商分类'){
        this.meragefactory();
      } else if (this.showHistery == '按产品分类'){
        this.merageserialName();
      }
    },
    //单元格合并
    merageInit() { // 在下文的时候会用到，对数据进行初始化是很有必要的
      this.firArr = [];
      this.firPos = 0;
      this.secArr = [];
      this.secPos = 0;
      /*this.theArr = [];
      this.thePos = 0;*/
    },
    meragefactory() {
      this.merageInit(); // 前文的初始化数据函数
      for (let i = 0; i < this.showList.length; i += 1) {
        if (i === 0) {
          // 第一行必须存在
          this.firArr.push(1);
          this.firPos = 0;
          this.secArr.push(1);
          this.secPos = 0;
          /*this.theArr.push(1);
          this.thePos = 0;*/
        } else {
          // 判断当前元素与上一个元素是否相同,eg：this.typeNamePos 是 this.typeNameArr序号
          // 第一列 下面的是eslint的不限制语法
          // eslint-disable-next-line no-lonely-if
          if (this.showList[i].factory === this.showList[i - 1].factory) {
            this.firArr[this.firPos] += 1;
            this.firArr.push(0);
          } else {
            this.firArr.push(1);
            this.firPos = i;
          }
          // 第二列
          if (this.showList[i].serialName === this.showList[i - 1].serialName && this.showList[i].factory ===
              this.showList[i - 1].factory) {
            this.secArr[this.secPos] += 1;
            this.secArr.push(0);
          } else {
            this.secArr.push(1);
            this.secPos = i;
          }
          /*// 第三列
          if (this.showList[i].paperNumber === this.showList[i - 1].paperNumber && this.showList[i].serialName
              === this.showList[i - 1].serialName && this.showList[i].factory ===
              this.showList[i - 1].factory) {
            this.theArr[this.thePos] += 1;
            this.theArr.push(0);
          } else {
            this.theArr.push(1);
            this.thePos = i;
          }*/
        }
      }
    },
    merageserialName() {
      this.merageInit(); // 前文的初始化数据函数
      for (let i = 0; i < this.showList.length; i += 1) {
        if (i === 0) {
          // 第一行必须存在
          this.firArr.push(1);
          this.firPos = 0;
          this.secArr.push(1);
          this.secPos = 0;
          /*this.theArr.push(1);
          this.thePos = 0;*/
        } else {
          // 判断当前元素与上一个元素是否相同,eg：this.typeNamePos 是 this.typeNameArr序号
          // 第一列 下面的是eslint的不限制语法
          // eslint-disable-next-line no-lonely-if
          if (this.showList[i].serialName === this.showList[i - 1].serialName) {
            this.firArr[this.firPos] += 1;
            this.firArr.push(0);
          } else {
            this.firArr.push(1);
            this.firPos = i;
          }
          // 第二列
          if (this.showList[i].serialName === this.showList[i - 1].serialName && this.showList[i].paperNumber ===
              this.showList[i - 1].paperNumber) {
            this.secArr[this.secPos] += 1;
            this.secArr.push(0);
          } else {
            this.secArr.push(1);
            this.secPos = i;
          }
          /*// 第三列
          if (this.showList[i].paperNumber === this.showList[i - 1].paperNumber && this.showList[i].serialName
              === this.showList[i - 1].serialName && this.showList[i].factory ===
              this.showList[i - 1].factory) {
            this.theArr[this.thePos] += 1;
            this.theArr.push(0);
          } else {
            this.theArr.push(1);
            this.thePos = i;
          }*/
        }
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        // 第一列的合并方法
        const row1 = this.firArr[rowIndex];
        const col1 = row1 > 0 ? 1 : 0; // 如果被合并了row = 0; 则他这个列需要取消
        return {
          rowspan: row1,
          colspan: col1,
        };
      } else if (columnIndex === 1) {
        // 第二列的合并方法
        const row2 = this.secArr[rowIndex];
        const col2 = row2 > 0 ? 1 : 0; // 如果被合并了row = 0; 则他这个列需要取消
        return {
          rowspan: row2,
          colspan: col2,
        };
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    switchOpen() {
      // let show = this.rukuSelectValue ? "是否取消筛选已入库工件" : "是否仅筛选已入库工件";
      // this.$confirm(show, '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   // this.rukuSelectValue = !this.rukuSelectValue;
        this.echartsDataSelect()
      // }).catch(() => {
      //   // this.$message({
      //   //   type: 'info',
      //   //   message: '已取消删除'
      //   // });
      // });
      // this.rukuSelectValue = !this.rukuSelectValue;
    },
  }
}
</script>

<style scoped>

</style>
